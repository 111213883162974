import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    staticClass: "fill-height justify-center flex-column",
    attrs: {
      "fluid": ""
    }
  }, [_c(VToolbar, {
    staticClass: "elevation-0 transparent media-toolbar flex-grow-0",
    staticStyle: {
      "width": "100%",
      "max-width": "640px !important"
    }
  }, [_c('button', {
    staticClass: "tw-btn tw-btn-icon-small tw-btn-icon-primary tw-mr-5",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.close
    }
  }, [_c('mdi-close', {
    staticClass: "tw-size-6"
  })], 1), _vm._v(" "), _c(VToolbarTitle, [_c('h5', [_vm._v(_vm._s(_vm.$t('action.creationOrder')))])]), _vm._v(" "), _c(VSpacer), _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-small tw-btn-outline-primary",
    attrs: {
      "type": "button"
    },
    domProps: {
      "textContent": _vm._s(_vm.$t('logout'))
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/logout'
        });
      }
    }
  })], 1), _vm._v(" "), _c('ContentArea', {
    staticClass: "d-flex flex-column align-center justify-center flex-grow-1",
    staticStyle: {
      "width": "100%",
      "max-width": "640px !important"
    }
  }, [_c(VIcon, {
    attrs: {
      "color": "error",
      "size": "48"
    }
  }, [_vm._v("mdi-alert-circle")]), _vm._v(" "), _c('div', {
    staticClass: "text-h5 mt-4"
  }, [_vm._v(_vm._s(_vm.errorTitle))]), _vm._v(" "), _c('div', {
    staticClass: "text-body-1 mt-4",
    staticStyle: {
      "max-width": "400px"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.errorMessage) + "\n    ")]), _vm._v(" "), _vm.settingErrorCodes.includes(_vm.code) ? _c('button', {
    staticClass: "tw-btn tw-btn-fill-primary mt-6",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.openSetting
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('action.openSendwowSetting')) + "\n      "), _c(VIcon, {
    staticClass: "ml-1",
    attrs: {
      "x-small": "",
      "color": "white"
    }
  }, [_vm._v("mdi-open-in-new")])], 1) : _vm._e(), _vm._v(" "), _vm.contact_id ? _c('button', {
    staticClass: "tw-btn tw-btn-fill-primary mt-6",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.openContactDetail
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('action.openContactDetail')) + "\n      "), _c(VIcon, {
    staticClass: "ml-1",
    attrs: {
      "x-small": "",
      "color": "white"
    }
  }, [_vm._v("mdi-open-in-new")])], 1) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };