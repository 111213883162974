import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.starts-with.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
export default {
  layout: 'default',
  data: function data() {
    return _objectSpread({
      crm: this.$route.query.crm,
      code: this.$route.query.code,
      detail: this.$route.query.detail,
      contact_id: this.$route.query.contact_id
    }, this.$getHistoryState('data'));
  },
  head: function head() {
    return {
      title: this.$t('error.general')
    };
  },
  computed: {
    crmName: function crmName() {
      var _this$crm, _this$crm2;
      return (_this$crm = this.crm) !== null && _this$crm !== void 0 && _this$crm.startsWith('salesforce') ? 'Salesforce' : (_this$crm2 = this.crm) !== null && _this$crm2 !== void 0 && _this$crm2.startsWith('hubspot') ? 'HubSpot' : 'CRM';
    },
    settingErrorCodes: function settingErrorCodes() {
      return ['integration_not_found'].concat(_toConsumableArray(this.crmErrorCodes));
    },
    crmErrorCodes: function crmErrorCodes() {
      return ['integration_inactive', 'crm_api_error', 'mapping_rule_error', 'required_field_error'];
    },
    crmTarget: function crmTarget() {
      var c = this.crm;
      switch (c) {
        case 'salesforce_lead':
          return this.$t('salesforce.lead');
        case 'salesforce_contact':
          return this.$t('salesforce.contact');
        case 'salesforce_account':
          return this.$t('salesforce.account');
        case 'hubspot_contact':
          return this.$t('contact');
        case 'hubspot_company':
          return this.$t('account');
        default:
          return this.$t('object');
      }
    },
    errorField: function errorField() {
      if (this.code === 'required_field_error') {
        var _this$crm3;
        var c = (_this$crm3 = this.crm) !== null && _this$crm3 !== void 0 ? _this$crm3 : 'salesforce_lead';
        var isContact = ['salesforce_lead', 'salesforce_contact', 'hubspot_contact'].includes(c);

        // 必須項目はコンタクトには姓、会社には会社名しかない
        return isContact ? this.$t('lastName') : this.$t('accountName');
      } else {
        return this.$t('data');
      }
    },
    errorTitle: function errorTitle() {
      switch (this.code) {
        case 'integration_not_found':
          return this.$t('error.extension.integrationNotFound', [this.crmName]);
        case 'integration_inactive':
          return this.$t('error.extension.integrationInactive', [this.crmName]);
        case 'crm_api_error':
          return this.$t('error.extension.crmApiError', [this.crmName]);
        case 'mapping_rule_error':
          return this.$t('error.extension.mappingRuleError', [this.crmName]);
        case 'required_field_error':
          return this.$t('error.extension.requiredFieldError', [this.crmName]);
        case 'delivery_block_error':
          return this.$t('error.extension.deliveryBlocked');
        default:
          return this.$t('error.general');
      }
    },
    errorMessage: function errorMessage() {
      switch (this.code) {
        case 'integration_not_found':
          return this.$t('error.extension.integrationNotFoundDetailForSend', [this.crmName]);
        case 'integration_inactive':
          return this.$t('error.extension.integrationInactiveDetailForSend', [this.crmName, this.crmTarget]);
        case 'crm_api_error':
          return this.$t('error.extension.crmApiErrorDetailForSend', [this.crmName]);
        case 'mapping_rule_error':
          return this.$t('error.extension.mappingRuleErrorDetailForSend');
        case 'required_field_error':
          return this.$t('error.extension.requiredFieldErrorDetailForSend', [this.crmName, this.crmTarget, this.errorField]);
        case 'delivery_block_error':
          return this.$t('error.extension.deliveryBlockDetail', [this.crmTarget]);
        default:
          return this.$t('error.detail');
      }
    }
  },
  methods: {
    close: function close() {
      window.parent.postMessage({
        smapo: true,
        action: 'close'
      }, '*');
      window.parent.postMessage('close', '*'); // FIXME 消す（Extension下位互換用）
    },
    openSetting: function openSetting() {
      if (this.crmErrorCodes.includes(this.code) && this.crmName !== 'CRM') {
        this.$open("/settings/integration/".concat(this.crmName.toLowerCase()));
      } else {
        this.$open('/settings/team-info');
      }
      this.close();
    },
    openContactDetail: function openContactDetail() {
      this.$open("/contact/".concat(this.contact_id));
      this.close();
    }
  }
};